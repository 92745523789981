import { useFormik } from 'formik'

export function useFormikHook(submit, validationSchema, initialValues) {
  const {
    handleChange,
    handleSubmit,
    setFieldTouched,
    setValues,
    setFieldValue,
    errors,
    touched,
    values,
  } = useFormik({
    initialValues: initialValues,
    onSubmit: submit,
    validationSchema: validationSchema,
  })

  return {
    handleChange,
    handleSubmit,
    setFieldTouched,
    setFieldValue,
    setValues,
    errors,
    touched,
    values,
  }
}
