import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import {
  CButton,
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CForm,
  CFormInput,
  CInputGroup,
  CInputGroupText,
  CRow,
} from '@coreui/react'
import CIcon from '@coreui/icons-react'
import { cilLockLocked, cilUser } from '@coreui/icons'
import './AdminLogin.css'
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth'
import * as yup from 'yup'
import { useFormikHook } from 'src/hooks/useFormikHook'
import { authentication } from 'src/App'
import { onAuthStateChanged } from 'firebase/auth'

const adminLoginBgImg = require('../../../assets/images/admin-login-bg.png')
const adminLogin2ndImg = require('../../../assets/images/adminLogin2ndImage.png')
const mekorot_logo = require('../../../assets/images/new-design/mekorot_logo.png')
const hopa_logo = require('../../../assets/images/new-design/hopa_logo.png')
const loginIcon = require('../../../assets/images/new-design/login.png')

const Login = () => {
  const navigate = useNavigate()

  const [username, setusername] = useState(false)
  const [password, setpassword] = useState(false)
  const email = 'mohsinzia@gmail44.com'
  const passw = '123@123'

  const user = () => {
    setusername(true)
    setpassword(false)
  }
  const pass = () => {
    setusername(false)
    setpassword(true)
  }
  const loading = (
    <div className="pt-3 text-center">
      <div className="sk-spinner sk-spinner-pulse"></div>
    </div>
  )

  const [isLoader, setIsLoader] = useState(true)
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(authentication, (user) => {
      setIsLoader(false)
      if (user) {
        navigate(`/dashboard`)
      } else {
      }
    })
    return () => unsubscribe()
  }, [])

  const loginbutton = (email, password) => {
    const auth = getAuth()
    // createUserWithEmailAndPassword(auth, email, password)
    //   .then((userCredential) => {
    //     // Signed in
    //     const user = userCredential.user
    //     console.log('user res', user)
    //     // ...
    //   })
    //   .catch((error) => {
    //     const errorCode = error.code
    //     const errorMessage = error.message
    //     console.log('error massege ', errorMessage)
    //     // ..
    //   })
    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        // Signed in
        const user = userCredential.user
        if (user) {
          localStorage.setItem('userId', user.uid)
          localStorage.setItem('accessToken', user.accessToken)
          navigate(`/dashboard`)
        } else {
          return
        }
        // ...
      })
      .catch((error) => {
        const errorCode = error.code
        const errorMessage = error.message
        console.log('error massege ', errorMessage)
        alert(errorCode)
        // ..
      })
  }

  const validationSchema = yup.object().shape({
    email: yup.string().required().label('dogName'),
    password: yup.string().required().label('OwnerName'),
  })

  const initialValues = {
    email: '',
    password: '',
  }

  const submit = async (values) => {
    const data = {
      email: values.email,
      password: values.password,
    }
    loginbutton(data.email, data.password)
  }

  const { handleChange, handleSubmit, setFieldTouched, errors, touched, values } = useFormikHook(
    submit,
    validationSchema,
    initialValues,
  )

  const submitdata = (data) => {}
  if (isLoader) {
    return <p>{loading}</p>
  } else {
    return (
      <div
        className="bg-light min-vh-100 d-flex flex-row align-items-center"
        style={{ backgroundImage: `url(${adminLoginBgImg})` }}
      >
        <div style={{ padding: '2% 10%' }}>
          <div className="row" style={{ background: '#ffffff', margin: 0 }}>
            <div className="col-md-4" style={{ padding: 0 }}>
              <img src={adminLogin2ndImg} alt="" style={{ width: '100%' }} />
            </div>
            <div className="col-md-8 p-5">
              <div
                className="d-flex justify-content-evenly"
                style={{ padding: '0 15% !important' }}
              >
                <img src={mekorot_logo} alt="mekorot" style={{ width: '200px', height: '125px' }} />
                <img
                  src={hopa_logo}
                  alt="mekorot"
                  style={{ width: '200px', height: '125px', paddingTop: '30px' }}
                />
              </div>
              <div className="adminPanelTitle">Mekorot Business Card - Admin Panel</div>
              <div className="loginForm">
                <form onSubmit={submitdata}>
                  <div className="Logininputs">
                    <div className="w-100">
                      <div className="form-floating mb-0">
                        <input
                          type="email"
                          className="form-control"
                          id="floatingInput"
                          placeholder="Enter user name"
                          onChange={handleChange('email')}
                          onBlur={() => {
                            setFieldTouched('email')
                          }}
                          autoFocus
                        />
                        <label htmlFor="floatingInput">Email address</label>
                      </div>
                      {username && (
                        <div
                          className=" w-10 bg-black absolute left-0 top-0 position-absolute"
                          style={{ width: '0.3rem', height: '100%' }}
                        ></div>
                      )}
                    </div>

                    <div className="w-100">
                      <div className="form-floating">
                        <input
                          type="password"
                          className="form-control w-100"
                          id="floatingPassword"
                          placeholder="Enter password"
                          onChange={handleChange('password')}
                          onBlur={() => {
                            setFieldTouched('password')
                          }}
                        />
                        <label htmlFor="floatingPassword">Password</label>
                      </div>

                      {password && (
                        <div
                          className=" w-10 bg-black absolute left-0 top-0 position-absolute"
                          style={{ width: '0.3rem', height: '100%' }}
                        ></div>
                      )}
                    </div>

                    <div
                      className="w-100 loginOptionDiv"
                      style={{ cursor: 'pointer' }}
                      onClick={() => submit(values)}
                      type="submit"
                    >
                      <h3>LOGIN</h3>
                      <img src={loginIcon} alt="" />
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Login
